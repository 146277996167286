import styles from "./MintInfo.module.css";
const MintInfo = () => {
  return (
    <div className={styles.mintInfoContainer}>
      <div className={styles.questionsWrapper}>
        <div className={styles.title}>faq</div>
        <div className={styles.questions}>
          <div className={styles.questionsData}>
            <div className={styles.question}>/ What is Grados?</div>
            <div className={styles.answer}>
              <br />
              Grados are never seen before generative characters, arrived at
              Grail to share adventures with the og Gradis. Grados is a brand
              new collection. Both Grados and Gradis will be playable in the
              full future version of the gradis game.
            </div>
            <div className={styles.question}>
              / When does Grados mint opens?
            </div>
            <div className={styles.answer}>
              <br />
              3 phases or until sold out:
              <br />
              <br />
              <strong>PHASE 1</strong> January 31, 3pm CET, 9am EST.
              <br />
              <br />
              24h Closed AL mint & Free claim. 2 mints per AL wallet. <br />
              AL includes all gradis holders and selected XCOPY holders.
              <br />
              <br />
              <strong>PHASE 2</strong> February 1, 3pm CET, 9am EST.
              <br />
              <br />
              Open AL mint to selected communities, TBA. First come first
              served, max 2 mints per wallet.
              <br />
              <br />
              <strong>PHASE 3</strong> February 1, 8pm CET, 2pm EST.
              <br />
              <br />
              Public mint, max 2 mints per wallet.
              <br />
            </div>
            <div className={styles.question}>/ Total Supply and price?</div>
            <div className={styles.answer}>
              {" "}
              <br />
              5807 Grados. 0.05 per token
            </div>
            <div className={styles.question}>/ Link?</div>
            <div className={styles.answer}>
              <br />
              Posted only in our official Twitter{" "}
              <a
                href="https://twitter.com/_gradis_/"
                target="_blank"
                rel="noreferrer"
                className={styles.twitterLink}
              >
                @_GRADIS_
              </a>{" "}
              right before minting opens. Double check, always.
            </div>
            <div className={styles.question}>
              / What is the Free Claim? When does it end?
            </div>
            <div className={styles.answer}>
              <br />
              Wallets holding gradis will be able to claim 1 free Grados per
              each 2 gradis held. For example, if you hold 8 gradis in the same
              wallet upon snapshot, you have a guaranteed 4 Grados to claim for
              free during 24 hours, from January 31, 3pm CET, 9am EST to
              February 1, 3pm EST. When the 24h period ends, unclaimed Grados
              will be unlocked and available to minters of the 2nd phase.
            </div>
            <div className={styles.question}>
              / Who gets Allowlist for the 1st phase?
            </div>
            <div className={styles.answer}>
              <br />
              <br />
              Wallets who hold any number of gradis upon snapshot.
              <br />
              <br />
              Wallets who hold an itemsito upon snapshot.
              <br />
              <br />
              1000 raffled XCOPY holders wallets will receive AL - check XCOPY
              discord.
              <br />
              <br />
              100 best scores on the Gradis demo game leaderboard.
            </div>
            <div className={styles.question}>/ Is AL overallocated?</div>
            <div className={styles.answer}>
              <br />
              At the time of writing this FAQ, AL is not overallocated based on
              the current number of wallets holding a gradis. However, and
              outside our control, numbers could change hours prior to snapshot.
              AL mints are not guaranteed. First comes, first served, in every
              phase. Free claims are 100% guaranteed. Remember, only 24 hours to
              claim starting January 31, 3pm CET, 9am EST.
            </div>
            <div className={styles.question}>
              / How many mints per AL? Can I free claim + mint?
            </div>
            <div className={styles.answer}>
              <br />2 mints max per AL. Yes, you can free claim + mint.
            </div>
            <div className={styles.question}>
              / When does the snapshot happen?
            </div>
            <div className={styles.answer}>
              <br />
              Snapshot happens on January 31, minutes before mint and claim
              open.
            </div>
            <div className={styles.question}>
              / Are Grados generative or pre-made?{" "}
            </div>
            <div className={styles.answer}>
              <br />
              Grados are fully generative and their randomness is 100% provably
              fair. Magic happens on each transaction, as happened with Gradis
              in 2021.
            </div>
            <div className={styles.question}>/ XCOPY has worked in Grados?</div>
            <div className={styles.answer}>
              <br />
              Yes, XCOPY has worked with us and created 2 characters for the
              collection. These will be mixed with bodies and gradients randomly
              to create generative Grados as happens with all the other 30
              characters.
            </div>
            <div className={styles.question}>/ When do Grados reveal? </div>
            <div className={styles.answer}>
              <br />
              Grados reveal is going to take place at least 5 days after minting
              ends. For Grados to be properly displayed in major marketplaces we
              need to generate a gif for each token. This process requires
              machine time and the gif script must go through every single mint.
            </div>
            <div className={styles.question}>
              / What is the game demo? Who can play?
            </div>
            <div className={styles.answer}>
              <br />
              The Gradis game demo is a 3D platformer featuring og Gradis, where
              the average user can have fun at its own pace and speedrunners can
              compete to get the fastest score and win prizes. It is open to
              everyone, verified gradis holders can play with their gradis and
              itemsitos connecting their wallet
            </div>
            <div className={styles.question}>
              / When and how can I play? Can I use my gradis?
            </div>
            <div className={styles.answer}>
              <br />
              Demo launches 25 January, 3pm EST, 9pm CET. Closes before mint
              opens, 31 January, 2pm CET, 8am EST. The only official link will
              be posted in our Twitter account{" "}
              <a
                href="https://twitter.com/_gradis_/"
                target="_blank"
                rel="noreferrer"
                className={styles.twitterLink}
              >
                @_GRADIS_
              </a>
              .
            </div>
            <div className={styles.question}>
              / Is there a leaderboard for best runs? And prizes?
            </div>
            <div className={styles.answer}>
              <br />
              Yes, there is going to be a leaderboard. For your round to count
              you must connect your wallet and enter your Twitter handle in the
              menu. Best 100 scores get AL for phase 1. Best 10 scores win 1
              Grados, top 3 win 2 grados and the best score overall wins 5
              Grados.
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className={styles.radiant}></div>
    </div>
  );
};

export default MintInfo;
