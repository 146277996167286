import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Marquee from "react-double-marquee";
import gradis from "../../static/gradis.png";
import styles from "./Home.module.css";
import gradisGame from "../../static/gradisGame.mp4";
import opensea from "../../static/opensea.png";
import TwoGradis from "../../static/TwoGradis.gif";
import ThreeGradis from "../../static/ThreeGradis.gif";
import convertito from "../../static/convertito.png";
import cc0 from "../../static/cc0.gif";
import lore from "../../static/lore.png";
import loreMobile from "../../static/loreMobile.png";
import gradosStatic from "../../static/grados-static.png";
import gradosAnimation from "../../static/grados-animation.gif";
import { GRADIS_ANCESTRY } from "../../commons/utils/gradis";
import itoAnimation from "../../static/ito.gif";
import ito from "../../static/ito.png";
import isAnimation from "../../static/is.gif";
import is from "../../static/is.png";
import isEnd from "../../static/isEnd.png";
import itoCreation from "../../static/itoCreation.png";
import twoD from "../../static/gif2dgradis.gif";
import threeD from "../../static/gif3dgradis.gif";
import marquee1 from "../../static/marquee1.png";
import marquee2 from "../../static/marquee2.png";
import cube from "../../static/cube.svg";
let currentIntersected;
let currentScrollY = 0;
const Home = () => {
  const itoImg = useRef(null);
  const isImg = useRef(null);
  const navigate = useNavigate();
  const [hideImg, setHideImg] = useState(false);

  const handleItoMouseOver = () => {
    itoImg.current.src = itoAnimation;
  };

  const handleItoMouseLeave = () => {
    itoImg.current.src = ito;
  };

  const handleIsMouseOver = () => {
    isImg.current.src = isAnimation;
  };

  const handleIsMouseLeave = () => {
    isImg.current.src = is;
  };

  const handleScroll = (event) => {
    // const viewHeight = window.innertHeight;
    const slide1 = document.getElementById("slide-1");
    const slide2 = document.getElementById("slide-2");
    const slide3 = document.getElementById("slide-3");
    const slide4 = document.getElementById("slide-4");
    const slide5 = document.getElementById("slide-5");
    const slide6 = document.getElementById("slide-6");
    const slide7 = document.getElementById("slide-7");
    const slide8 = document.getElementById("slide-8");
    const slide9 = document.getElementById("slide-9");
    switch (currentIntersected) {
      case "slide-1": {
        if (event.wheelDelta < 0) {
          slide2.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        currentScrollY = window.scrollY;
        break;
      }
      case "slide-2": {
        if (event.wheelDelta < 0) {
          slide3.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        } else {
          slide1.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        currentScrollY = window.scrollY;
        break;
      }
      case "slide-3": {
        if (event.wheelDelta < 0) {
          slide4.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        } else {
          slide2.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        currentScrollY = window.scrollY;
        break;
      }
      case "slide-4": {
        if (event.wheelDelta < 0) {
          slide5.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        } else {
          slide3.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        break;
      }
      case "slide-5": {
        if (event.wheelDelta < 0) {
          slide6.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        } else {
          slide4.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        break;
      }
      case "slide-6": {
        if (event.wheelDelta < 0) {
          slide7.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        } else {
          slide5.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        break;
      }
      case "slide-7": {
        if (event.wheelDelta < 0) {
          slide8.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        } else {
          slide6.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    const itoElement = itoImg.current;
    const isElement = isImg.current;
    itoElement.addEventListener("mouseover", handleItoMouseOver);
    itoElement.addEventListener("mouseleave", handleItoMouseLeave);

    isElement.addEventListener("mouseover", handleIsMouseOver);
    isElement.addEventListener("mouseleave", handleIsMouseLeave);

    // window.addEventListener("wheel", handleScroll);

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          currentIntersected = entries[0].target.id;
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const sliders = document.querySelectorAll(`[id^="slide-"]`);
    sliders.forEach((slide) => observer.observe(slide));

    return () => {
      itoElement.removeEventListener("mouseover", handleItoMouseOver);
      itoElement.removeEventListener("mouseleave", handleItoMouseLeave);
      isElement.removeEventListener("mouseover", handleIsMouseOver);
      isElement.removeEventListener("mouseleave", handleIsMouseLeave);
      // window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const handleSubmit = (event) => {};
  const handleChange = (event) => {
    navigate(`../lores/${event.target.value}`);
  };

  const renderSelectOptions = () => {
    return GRADIS_ANCESTRY.map((gradis) => (
      <option key={gradis} value={gradis}>
        {gradis}
      </option>
    ));
  };

  return (
    <div className={styles.container}>
      <div className={styles.videoHome} id="slide-1">
        <div className={styles.gradisMarquee}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <Marquee direction="left" speed="0.06" delay="0">
              <img src={marquee1} alt="kangois" height="50" />
            </Marquee>
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <Marquee direction="left" speed="0.03" delay="0">
              <img src={marquee2} alt="race" height="50" />
            </Marquee>
          </div>
        </div>
        <div className={styles.gradisMarquee2}></div>
        <div className={styles.imageContainer}>
          <img src={gradis} alt="gradis" className={styles.gradisImage} />
        </div>
        <video autoPlay loop muted playsInline className={styles.video}>
          <source src={gradisGame} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div className={styles.openseaSection} id="slide-2">
        <div className={styles.platformerContainer}>
          <div className={styles.platformerText}>
            <div className={styles.platformerTitle}>PLATFORMER GAME</div>
            <br />
            <span className={styles.platformerDescription}>
              We are building a web 3 native experience like no other.
              <br />
              Race with gradis you own. Proof of win, proof of fun.
            </span>
            <div className={styles.openseaLinkContainer}>
              <a
                href="https://opensea.io/collection/gradis"
                target="_blank"
                rel="noreferrer"
                className={styles.openseaLinkMobile}
              >
                COLLECT GRADIS &nbsp;
                <img
                  src={opensea}
                  alt="opensea"
                  className={styles.openseaIcon}
                />
              </a>
            </div>
          </div>

          <a
            href="https://opensea.io/collection/gradis"
            target="_blank"
            rel="noreferrer"
            className={styles.openseaLink}
          >
            COLLECT GRADIS &nbsp;
            <img src={opensea} alt="opensea" className={styles.openseaIcon} />
          </a>

          <div className={styles.disclaimerTextMobile}>
            Rare tokens? Check our{" "}
            <a href="/rarity" alt="rarity" className={styles.rarityLink}>
              rarity
            </a>{" "}
            rank
          </div>
        </div>
        <div className={styles.openseaDisclaimerText}>
          <div className={styles.disclaimerText}>
            *Looking for rare tokens?*
          </div>
          <div className={styles.disclaimerText}>
            &nbsp;Gradis generation was 100% random and on-mint. Discover the
            rankings of{" "}
            <a href="/rarity" alt="rarity" className={styles.rarityLink}>
              rarest
            </a>{" "}
            gradis
          </div>
        </div>
      </div>
      <div className={styles.convertito} id="slide-3">
        <div className={styles.convertitoContainer}>
          <div className={styles.convertitoText}>
            <span className={styles.how}>
              How
              <br />
              it
              <br />
              works
            </span>
            <img src={convertito} alt="from 2d to 3d" />
          </div>
          <div className={styles.gradisContainer}>
            <div className={styles.gradisImages}>
              <img
                src={TwoGradis}
                alt="2d Gradis"
                className={styles.convertitoImage}
              />
              <div className={styles.titles1}>OG 2D token</div>
              <div className={styles.explanation1}>
                These are the original 4193 tokens,
                <br />
                minted and generated randomly in 2021.
                <br />
                They live in wallets and can be{" "}
                <a
                  href="https://opensea.io/collection/gradis"
                  className={styles.underline}
                  target="_blank"
                  rel="noreferrer"
                >
                  traded
                </a>
                .
                <br />
                Code is law. Check script versions at
                <br />
                <a
                  href="https://2d.gradis.art/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.underline}
                >
                  2d.gradis.art/?tokenid
                </a>
              </div>
              <div className={styles.explanation1Mobile}>
                These are the original 4193 tokens, minted and generated
                randomly in 2021. They live in wallets and can be
                <a
                  href="https://opensea.io/collection/gradis"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.underline}
                >
                  traded
                </a>
                . Code is law. Check script versions at
                <a
                  href="https://2d.gradis.art/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.underline}
                >
                  2d.gradis.art/?tokenid
                </a>
              </div>
            </div>
            <div className={styles.gradisImages}>
              <img
                src={ThreeGradis}
                alt="3d Gradis"
                className={styles.convertitoImage2}
              />
              <div className={styles.titles2}>3D ready to play</div>
              <div className={styles.explanation2}>
                <a
                  href="https://convertito.gradis.art/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.underline}
                >
                  Convertito
                </a>{" "}
                transforms any possible gradis
                <br />
                into 3D playable characters. Built within Unity
                <br /> game engine. Connect your wallet to view your
                <br />
                collectionand play. Or browse through almost
                <br /> infinite random gradis
              </div>
              <div className={styles.explanation2Mobile}>
                <a
                  href="https://convertito.gradis.art/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.underline}
                >
                  Convertito
                </a>{" "}
                transforms any possible gradis into 3D playable characters.
                Built within Unity game engine. Connect your wallet to view your
                collectionand play. Or browse through almost infinite random
                gradis
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cco} id="slide-4">
        <div className={styles.ccoContainer}>
          <h3 className={styles.ccoTitle}>
            COLLECT, PLAY AND...CREATE - <span className={styles.cc0}>CC0</span>
          </h3>
          <h3 className={styles.ccoTitleMobile}>COLLECT, PLAY AND...CREATE</h3>
          <h3 className={styles.ccoTitleMobile}>
            <span className={styles.cc0}>CC0</span>
          </h3>
          <div className={styles.ccoDescContainer}>
            <p className={styles.ccoDescription}>
              All gradis have a Creative Commons 0 license
              <br />
              The original 2D-vector-animation-script is{" "}
              <a
                href="https://github.com/JeanOsorio/GradisNFT"
                target="_blank"
                rel="noreferrer"
                className={styles.underlineGreen}
              >
                open source
              </a>
              <br />
              3D heads are ready to be used. Free for anyone to build with.
            </p>
          </div>
        </div>
        <div className={styles.ccoIframeContainer}>
          <iframe
            src="https://my.spline.design/untitled-f4cb152d0ebc71e0426fb9debac621e7/"
            frameBorder="0"
            width="100%"
            height="100%"
          ></iframe>
        </div>
        <a
          href="https://cdn.gradis.art/gradis-miscellaneous/GRADIS_3D_heads.fbx"
          target="_blank"
          rel="noreferrer"
          className={styles.ccoLink}
        >
          .fbx 3D gradis heads pack
        </a>
        <div className={styles.ccoLinkContainer}>
          <img src={cube} alt="cube" className={styles.cube} />
          <a
            href="https://cdn.gradis.art/gradis-miscellaneous/GRADIS_3D_heads.fbx"
            target="_blank"
            rel="noreferrer"
            className={styles.ccoLinkWhite}
          >
            .fbx 3D gradis heads pack
          </a>
        </div>
      </div>
      <div className={styles.lore} id="lores">
        <div className={styles.loreButton}>
          <div className={styles.explanation3}>
            Our community studied the 40 ancestries
            <br /> We went to Grail, gradis universe, and explored
          </div>
          <form onSubmit={handleSubmit}>
            <select
              className={styles.loreSelect}
              onChange={handleChange}
              defaultValue=""
            >
              <option value="" hidden>
                select an ancestry
              </option>
              {renderSelectOptions()}
            </select>
          </form>
        </div>
      </div>
      <div className={styles.gradisGods}>
        <div id="slide-6">
          <img
            src={ito}
            alt="ito"
            ref={itoImg}
            className={styles.gradisItoImage}
          />
        </div>
        <div>
          <div className={styles.gradisItoCreationContainer}>
            <div>
              <img
                src={itoCreation}
                alt="is"
                className={styles.itoCreationImage}
              />
              <p>
                Ito is one of the two gods
                <br />
                on the mythical world of Grail -
                <br /> the home of all Gradis.
                <br /> Ito is made of pure energy, spawning
                <br />
                from all surfaces across the land
                <br />
                as light beams form it’s body.
                <br /> Finding Ito is rumored to give good luck.
                <br /> Legend states that all Gradis come from
                <br /> and are created from the energy of Ito.
                <br /> the beginning of all Gradis.
              </p>
            </div>
          </div>
          <div className={styles.gradisItoCreationContainerMobile}>
            <div className={styles.itoContainerMobile}>
              <div className={styles.itoContainer}>
                <img
                  src={itoCreation}
                  alt="is"
                  className={styles.itoCreationImage}
                />
              </div>
              <p>
                Ito is one of the two gods on the mythical world of Grail - the
                home of all Gradis. Ito is made of pure energy, spawning from
                all surfaces across the land as light beams form it’s body.
                Finding Ito is rumored to give good luck. Legend states that all
                Gradis come from and are created from the energy of Ito. the
                beginning of all Gradis.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.isImageMobile}>
          <img src={is} ref={isImg} alt="is" className={styles.gradisIsImage} />
        </div>
        <div className={styles.gradisIsWholeContainer}>
          <div className={styles.gradisIsEndContainer} id="slide-7">
            <div className={styles.isContainer}>
              <img src={isEnd} alt="is" className={styles.isEndImage} />
              <p>
                Completing the divine duality of the world of
                <br /> Grail.
                <br /> Rarely seen, feared by many, nobody fully understands
                <br />
                the full power of Is.
                <br />
                Legend states that all Gradis end their lives in Is.
              </p>
            </div>
            <div className={styles.isContainerMobile}>
              <div className={styles.isEndImageContainer}>
                <img src={isEnd} alt="is" className={styles.isEndImage} />
              </div>
              <p>
                Completing the divine duality of the world of Grail.
                <br /> Rarely seen, feared by many, nobody fully understands
                <br />
                the full power of Is.
                <br />
                Legend states that all Gradis end their lives in Is.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.isImageDesktop}>
          <img src={is} ref={isImg} alt="is" className={styles.gradisIsImage} />
        </div>
      </div>
      <div className={styles.footer} id="slide-8">
        <div className={styles.footerText}>
          <h3 className={styles.footerTitle}>GET RANDOM</h3>
          <span className={styles.footerDescription}>
            About to leave? Explore thousands of random combos.
            <br />
            Have fun in the native 2D script or in 3D convertito :)
          </span>
          <span className={styles.footerDescriptionMobile}>
            About to leave? Explore thousands of random combos. Have fun in the
            native 2D script or in 3D convertito :)
          </span>
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.footerImageContainer}>
            <a
              href="https://2d.gradis.art/"
              target="_blank"
              className={styles.footerButton}
              rel="noreferrer"
            >
              2d random script
            </a>
            <br />
            <img src={twoD} alt="original script" className={styles.twoD} />
          </div>
          <div className={styles.footerImageContainer}>
            <a
              href="https://convertito.gradis.art/"
              target="_blank"
              className={styles.footerButton}
              rel="noreferrer"
            >
              3d convertito
            </a>
            <br />
            <img src={threeD} alt="3d convertito" className={styles.threeD} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
