import { useEffect, useState } from "react";
import styles from "./Lore.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { loresData } from "./loresData";
import { generateTokens } from "../../../commons/utils/token";

const GRADIS_BACKGROUND_COLORS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
];

const BACKGROUND_COLORS = [
  "rgb(0,0,0)",
  "rgb(48,0,0)",
  "rgb(0,11,41)",
  "rgb(61,61,61)",
  "rgb(4,28,0)",
  "rgb(39,0,41)",
  "rgb(9,38,36)",
  "rgb(193,235,56)",
  "rgb(73,56,235)",
];

const Lore = () => {
  const [currentLore, setCurrentLore] = useState(null);
  const [afterLore, setAfterLore] = useState(null);
  const [beforeLore, setBeforeLore] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.gradisId) {
      const gradis = loresData.find(
        (ld) => ld.ancestry === params.gradisId.toLowerCase()
      );

      if (gradis !== undefined) {
        setCurrentLore(gradis);
      } else {
        navigate("/lores");
      }
    }
  }, [params, navigate]);

  useEffect(() => {
    console.log("....", currentLore);
    if (currentLore) {
      const currentBackground =
        currentLore.ancestryNumber !== 41
          ? GRADIS_BACKGROUND_COLORS[Math.floor(Math.random() * 9)]
          : GRADIS_BACKGROUND_COLORS[8];
      setCurrentToken(
        generateTokens(1, currentLore.ancestryNumber, currentBackground)
      );
      document.body.style.backgroundColor =
        BACKGROUND_COLORS[parseInt(currentBackground)];
      document.querySelector("#NavBar").style.background =
        BACKGROUND_COLORS[parseInt(currentBackground)];
      let beforeGradis;
      let afterGradis;
      if (currentLore.ancestryNumber > 1 && currentLore.ancestryNumber < 41) {
        console.log("primera");
        beforeGradis = loresData.find(
          (ld) => ld.ancestryNumber === currentLore.ancestryNumber - 1
        );
        afterGradis = loresData.find(
          (ld) => ld.ancestryNumber === parseInt(currentLore.ancestryNumber) + 1
        );
        // afterGradis = loresData.find(
        //   (ld) => ld.ancestryNumber === currentLore.ancestryNumber + 1
        // );
        setAfterLore(afterGradis);
        setBeforeLore(beforeGradis);
      }
      if (currentLore.ancestryNumber === 1) {
        console.log("segunda");
        afterGradis = loresData.find(
          (ld) => ld.ancestryNumber === currentLore.ancestryNumber + 1
        );
        beforeGradis = null;
        setAfterLore(afterGradis);
        setBeforeLore(beforeGradis);
      }

      if (currentLore.ancestryNumber === 41) {
        console.log("tercera");
        beforeGradis = loresData.find(
          (ld) => ld.ancestryNumber === currentLore.ancestryNumber - 1
        );
        afterGradis = null;
        setAfterLore(afterGradis);
        setBeforeLore(beforeGradis);
      }
    }
    return () => {
      document.body.style.backgroundColor = "#000000";
      document.querySelector("#NavBar").style.background = "rgb(73,56,235)";
    };
  }, [currentLore]);

  const handleChangePage = (event) => {
    if (event.target.dataset.direction === "foward") {
      navigate("/lores/" + afterLore.ancestry);
    } else {
      navigate("/lores/" + beforeLore.ancestry);
    }
  };

  if (!currentLore) {
    return;
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            {currentLore && currentLore.ancestryNumber !== 41 && (
              <>
                <iframe
                  src={`https://2d.gradis.art/?tokenid=${currentToken}`}
                  title="Gradis"
                  frameBorder="0"
                  className={styles.loreIframe}
                ></iframe>
                <span className={styles.author}>
                  {currentLore.author
                    ? `explored by ${currentLore.author}`
                    : ""}
                </span>
              </>
            )}
            {currentLore && currentLore.ancestryNumber === 41 && (
              <>
                <div
                  title="Gradis"
                  frameBorder="0"
                  className={styles.loreIframe}
                >
                  <img
                    src="https://cdn.gradis.art/gradis-gif/4.png"
                    alt="shamis"
                    className={styles.loreIframe}
                  />
                </div>
                <span className={styles.author}>
                  {currentLore.author
                    ? `explored by ${currentLore.author}`
                    : ""}
                </span>
              </>
            )}
          </div>
          <div className={styles.infoContainer}>
            {!currentLore.origin && (
              <div className={styles.dataTextContainer}>
                <h3 className={styles.subtitle}>Missing Report</h3>
                <p className={styles.dataText}>
                  {currentLore.ancestry.charAt(0).toUpperCase() +
                    currentLore.ancestry.slice(1)}
                </p>
                <br />
                <p className={styles.dataText}>
                  Did you explore this ancestry? If you went to Grail and
                  investigated deep enough, send us your report via Twitter DM.
                  Hurry up traveller!
                </p>

                <br />
                <p className={styles.dataText}>
                  Report must cover Ancestry name, Origin, Mood, Strength and
                  Weakness, Affinity, Special power and a Curiosity. Check other
                  ancestries for reference.
                </p>
                <br />

                <p className={styles.dataText}>
                  Along with your report, send your wallet address. Thank your
                  for sharing this adventure with us, explorer.
                </p>
              </div>
            )}

            {currentLore.origin && (
              <div className={styles.dataTextContainer}>
                <h3 className={styles.subtitle}>Ancestry</h3>
                <p className={styles.dataText}>
                  {currentLore.ancestry.charAt(0).toUpperCase() +
                    currentLore.ancestry.slice(1)}
                </p>
                {currentLore.origin && (
                  <h3 className={styles.subtitle}>Origin</h3>
                )}
                <p className={styles.dataText}>{currentLore.origin}</p>
                {currentLore.mood && <h3 className={styles.subtitle}>Mood</h3>}
                <p className={styles.dataText}>{currentLore.mood}</p>
                {currentLore.strength && (
                  <h3 className={styles.subtitle}>Strength and weakness</h3>
                )}
                <p className={styles.dataText}>{currentLore.strength}</p>
                {currentLore.affinity && (
                  <h3 className={styles.subtitle}>Affinity</h3>
                )}
                <p className={styles.dataText}>{currentLore.affinity}</p>
                {currentLore.power && (
                  <h3 className={styles.subtitle}>Special Power</h3>
                )}
                <p className={styles.dataText}>{currentLore.power}</p>
                {currentLore.curiosity && (
                  <h3 className={styles.subtitle}>Curiosity</h3>
                )}
                {currentLore.curiosity && (
                  <p className={styles.dataText}>{currentLore.curiosity}</p>
                )}
                <span className={styles.explorer}>
                  <h3 className={styles.subtitle}>Explorer</h3>
                  <p className={styles.dataText}>{currentLore.author}</p>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.paginatorWrapperBottom}>
        <div className={styles.linkAlign}>
          <a href="/#lores" alt="all ancestry" className={styles.allAncestry}>
            [all ancestries]
          </a>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.loreButtons}
            onClick={handleChangePage}
            data-direction="back"
          >
            {"<"}
          </button>
          <div className={styles.pageLabel}>
            {beforeLore?.ancestry} | {afterLore?.ancestry}
          </div>
          <button
            className={styles.loreButtons}
            onClick={handleChangePage}
            data-direction="foward"
          >
            {">"}
          </button>
        </div>
        <div>&nbsp;</div>
      </div>
      <div className={styles.paginatorWrapperLinkMobile}>
        <a href="/#lores" alt="all ancestry" className={styles.allAncestry}>
          [all ancestries]
        </a>
      </div>
    </>
  );
};

export default Lore;
