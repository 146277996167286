import {
  SPECIES,
  BODY,
  GRADIS_BASE_COLORS,
  GRADIS_HEAD_STOPS,
  GRADIS_AMOUNT_OF_COLORS,
  GRADIS_SECONDARY_COLORS,
  GRADIS_BODY_STOPS,
  GRADIS_AMOUNT_OF_BODY_COLORS,
  GRADIS_BACKGROUND_COLORS,
} from "../utils/gradis";
import { Random } from "./random";

export const generateTokens = (
  numberOfToken,
  specie = null,
  background = null
) => {
  let tokens = [];
  const random = new Random(parseInt(1234567890));
  for (let index = 0; index < numberOfToken; index++) {
    let token = ["10"];
    token.push(`${specie}`.length === 1 ? `0${specie}` : specie); // specie
    token.push(BODY[random.random_int(0, 99)]); // body
    token.push(random.random_choice(GRADIS_BASE_COLORS)); // baseColor
    token.push(random.random_choice(GRADIS_HEAD_STOPS)); // headStops
    const amountOfColors = random.random_choice(GRADIS_AMOUNT_OF_COLORS); // amountOfColors
    token.push(amountOfColors); // add amountOfColors
    for (let index = 0; index < parseInt(amountOfColors); index++) {
      token.push(random.random_choice(GRADIS_SECONDARY_COLORS)); // secondaryColors
    }
    token.push(random.random_choice(GRADIS_BODY_STOPS)); // bodyStops
    const amountOfBodyColors = random.random_choice(
      GRADIS_AMOUNT_OF_BODY_COLORS
    ); // amountOfBodyColors
    token.push(amountOfBodyColors); // add amountOfBodyColors
    for (let index = 0; index < parseInt(amountOfBodyColors); index++) {
      token.push(random.random_choice(GRADIS_SECONDARY_COLORS)); // secondaryColors
    }

    const bgColor = background
      ? background
      : random.random_choice(GRADIS_BACKGROUND_COLORS);
    token.push(bgColor); // backgroundColor
    tokens.push(token.join(""));

    return tokens[0];
  }

  return tokens;
};
