// if start with 00 find array position, el real number
const SPECIES = [
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "04",
  "04",
  "05",
  "05",
  "05",
  "05",
  "06",
  "06",
  "06",
  "06",
  "07",
  "07",
  "07",
  "07",
  "07",
  "07",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "09",
  "09",
  "09",
  "09",
  "09",
  "09",
  "09",
  "09",
  "10",
  "10",
  "10",
  "10",
  "11",
  "11",
  "11",
  "11",
  "12",
  "13",
  "13",
  "14",
  "14",
  "14",
  "14",
  "14",
  "14",
  "15",
  "15",
  "15",
  "15",
  "16",
  "16",
  "16",
  "16",
  "17",
  "17",
  "17",
  "17",
  "17",
  "17",
  "18",
  "18",
  "18",
  "18",
  "18",
  "18",
  "18",
  "18",
  "18",
  "18",
  "19",
  "19",
  "19",
  "19",
  "20",
  "20",
  "20",
  "20",
  "21",
  "21",
  "21",
  "21",
  "21",
  "21",
  "22",
  "22",
  "23",
  "23",
  "23",
  "23",
  "23",
  "23",
  "24",
  "24",
  "24",
  "24",
  "25",
  "25",
  "25",
  "25",
  "26",
  "26",
  "26",
  "26",
  "26",
  "26",
  "27",
  "27",
  "27",
  "27",
  "28",
  "28",
  "28",
  "28",
  "28",
  "28",
  "29",
  "29",
  "30",
  "30",
  "30",
  "30",
  "30",
  "30",
  "30",
  "30",
  "30",
  "30",
  "30",
  "30",
  "31",
  "31",
  "31",
  "31",
  "31",
  "31",
  "32",
  "33",
  "33",
  "33",
  "33",
  "33",
  "33",
  "34",
  "34",
  "35",
  "35",
  "36",
  "36",
  "37",
  "37",
  "37",
  "37",
  "38",
  "38",
  "38",
  "38",
  "38",
  "38",
  "39",
  "40",
].sort(() => {
  return Math.random() - 0.5;
});

const BODY = [
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "06",
  "06",
  "06",
  "06",
  "06",
  "07",
  "07",
  "07",
  "07",
  "08",
].sort(() => {
  return Math.random() - 0.5;
});

const GRADIS_BASE_COLORS = [
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "07",
  "07",
  "07",
  "07",
  "07",
  "07",
  "07",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "08",
  "09",
  "09",
  "09",
  "09",
  "09",
  "09",
  "09",
  "10",
  "10",
  "10",
  "10",
  "10",
  "10",
  "11",
  "11",
  "11",
  "11",
  "11",
  "11",
  "11",
  "12",
  "13",
  "14",
  "15",
  "15",
  "15",
  "15",
  "15",
  "15",
  "15",
  "16",
  "16",
  "16",
  "16",
  "16",
  "16",
  "16",
].sort(() => {
  return Math.random() - 0.5;
});

const GRADIS_HEAD_STOPS = [
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "04",
  "04",
  "04",
  "04",
  "04",
  "01",
  "01",
  "01",
  "08",
].sort(() => {
  return Math.random() - 0.5;
});

const GRADIS_AMOUNT_OF_COLORS = [
  "01",
  "01",
  "01",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
].sort(() => {
  return Math.random() - 0.5;
});

const GRADIS_SECONDARY_COLORS = [
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "05",
  "05",
  "05",
  "05",
  "05",
  "06",
  "06",
  "06",
  "06",
  "06",
  "07",
  "07",
  "07",
  "07",
  "07",
  "08",
  "08",
  "08",
  "08",
  "08",
  "09",
  "09",
  "09",
  "09",
  "09",
  "10",
  "10",
  "10",
  "10",
  "10",
  "11",
  "11",
  "11",
  "11",
  "11",
  "12",
  "12",
  "13",
  "13",
  "14",
  "14",
  "14",
  "15",
  "15",
  "15",
  "16",
  "16",
  "16",
  "17",
  "17",
].sort(() => {
  return Math.random() - 0.5;
});

const GRADIS_BODY_STOPS = [
  "01",
  "01",
  "01",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
].sort(() => {
  return Math.random() - 0.5;
});

const GRADIS_AMOUNT_OF_BODY_COLORS = [
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "03",
].sort(() => {
  return Math.random() - 0.5;
});

const GRADIS_BACKGROUND_COLORS = [
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "00",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "01",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "02",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "03",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "04",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "05",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "06",
  "07",
  "08",
  "08",
  "08",
].sort(() => {
  return Math.random() - 0.5;
});

const GRADIS_ANCESTRY = [
  "Apis",
  "Beamis",
  "Bombis",
  "Botito",
  "Brokito",
  "Buddis",
  "Bullito",
  "Caubis",
  "Comfis",
  "Cralito",
  "Darkis",
  "Drudis",
  "Dubbito",
  "Duckito",
  "Dugis",
  "Elementis",
  "Entis",
  "Flamito",
  "Frogito",
  "Gmis",
  "Gradito",
  "Kangois",
  "Limis",
  "Nobis",
  "Oris",
  "Plantis",
  "Rabbis",
  "Saitais",
  "Samito",
  "Sanctis",
  "Saodis",
  "Septis",
  "Shienito",
  "Skellis",
  "Smois",
  "Spidis",
  "Spuokis",
  "Staccis",
  "Tigrito",
  "Xis",
  "Shamis",
];

export {
  BODY,
  GRADIS_AMOUNT_OF_BODY_COLORS,
  GRADIS_AMOUNT_OF_COLORS,
  GRADIS_ANCESTRY,
  GRADIS_BACKGROUND_COLORS,
  GRADIS_BASE_COLORS,
  GRADIS_BODY_STOPS,
  GRADIS_HEAD_STOPS,
  GRADIS_SECONDARY_COLORS,
  SPECIES,
};
