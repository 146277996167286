import { Routes, Route, Outlet, BrowserRouter } from "react-router-dom";
import { appRoutes } from "../commons/constants";
import { Main as MainLayout } from "../commons/components/layouts/Main";

import Home from "./Home";
/* import Mint from "./Mint"; */
/* import Admin from "./Admin"; */
import MintInfo from "./MintInfo";
import { Lores, Lore } from "./lores";
import Rarity from "./Rarity";
import Leaderboard from "./Leaderboard";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          <Route path={appRoutes.MINT} element={<Home />} />
          {/* <Route path={appRoutes.GRADOS} element={<Admin />} /> */}
          <Route path={appRoutes.MINT_INFO} element={<MintInfo />} />
          <Route path={appRoutes.LORES} element={<Lores />}></Route>
          <Route path={appRoutes.LORE} element={<Lore />} />
          <Route path={appRoutes.RARITY} element={<Rarity />} />
          <Route path={appRoutes.LEADERBOARD} element={<Leaderboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
