import { useState } from "react";
import { NavLink } from "react-router-dom";
import { NavBar } from "./NavBar";
import { Logo } from "./Logo";
import { Menu } from "./Menu";
import styles from "./TopNavBar.module.css";
import menuIcon from "../../../../../static/menu.svg";
import { appRoutes } from "../../../../constants/";

function TopNavBar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleClick = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <>
      <NavBar>
        <Logo />
        <Menu />
        <div className={styles.hamburgerContainer}>
          <button className={styles.menuButton} onClick={handleClick}>
            <img src={menuIcon} alt="menu" />
          </button>
        </div>
      </NavBar>
      {toggleMenu && (
        <div className={styles.menuMobile}>
          <div className={styles.linksContainer}>
            <a href={appRoutes.LEADERBOARD} className={styles.unactiveStyle}>
              Leaderboard
            </a>

            <a
              href={appRoutes.OPENSEA}
              target="_blank"
              rel="noreferrer"
              className={styles.unactiveStyle}
            >
              OpenSea
            </a>
            <a
              href={appRoutes.TWITTER}
              target="_blank"
              rel="noreferrer"
              className={styles.unactiveStyle}
            >
              Twitter
            </a>
            <a
              href={appRoutes.Discord}
              target="_blank"
              rel="noreferrer"
              className={styles.unactiveStyle}
            >
              Discord
            </a>
            <NavLink
              to={appRoutes.RARITY}
              className={({ isActive }) =>
                isActive ? styles.activeStyle : styles.unactiveStyle
              }
            >
              Rarity
            </NavLink>
            <a
              href={appRoutes.CONVERTITO}
              rel="noreferrer"
              target="_blank"
              className={styles.unactiveStyle}
            >
              Convertito 3D
            </a>
          </div>
        </div>
      )}
    </>
  );
}

export default TopNavBar;
