import { Wrapper } from "./components/Wrapper";
import TopNavBar from "./components/TopNavBar";
import Footer from "./components/Footer";

function Main(props) {
  const { children } = props;
  return (
    <Wrapper>
      <TopNavBar />
      <div>{children}</div>
      <Footer />
    </Wrapper>
  );
}

export { Main };
