import { Link } from "react-router-dom";
import styles from "./Lores.module.css";
import { apis, bombis, brokito, bullito, caubis, comfis } from "./heads";

const Lores = () => {
  return (
    <div className={styles.LoresWrapper}>
      <div className={styles.LoresContainer}>
        <Link to="/lores/apis">
          <img src={apis} alt="discover" className={styles.heads} />
        </Link>
        <Link to="/lores/bombis">
          <img src={bombis} alt="discover" className={styles.heads} />
        </Link>

        <Link to="/lores/brokito">
          <img src={brokito} alt="discover" className={styles.heads} />
        </Link>

        <Link to="/lores/bullito">
          <img src={bullito} alt="discover" className={styles.heads} />
        </Link>

        <Link to="/lores/caubis">
          <img src={caubis} alt="discover" className={styles.heads} />
        </Link>

        <Link to="/lores/comfis">
          <img src={comfis} alt="discover" className={styles.heads} />
        </Link>
      </div>
    </div>
  );
};

export default Lores;
