import fetchCall from "./CommunicationServices";

function GradisService() {
  function saveTokens(tokens) {
    const headers = {
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("public/tokens", {}, headers, "POST", { tokens })
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  }

  function generateGif(tokens) {
    const headers = {
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("public/check-gif", {}, headers, "POST", { tokens })
      .then((response) => response.tokensUrl)
      .catch((error) => {
        throw new Error(error);
      });
  }

  function mintedTokens(tokens) {
    const headers = {
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("public/minted", {}, headers, "POST", { tokens })
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  }

  function checkWhitelist(wallet) {
    const headers = {
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("public/check-whitelist", {}, headers, "POST", { wallet })
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  }

  function getRanking(params = {}) {
    const page = params.page ? params.page : 1;

    let url = `public/get-ranking?perPage=20&page=${page}`;

    if (params.order) {
      url = url.concat(`&order=${params.order}`);
    }

    if (params.token) {
      url = url.concat(`&token=${params.token}`);
    }

    if (params.ancestry && params.ancestry.length && !params.token) {
      params.ancestry.forEach(
        (ancestry) => (url = url.concat(`&ancestry=${ancestry}`))
      );
    }

    if (params.background && params.background.length && !params.token) {
      params.background.forEach(
        (background) =>
          (url = url.concat(`&background=${background.toLowerCase()}`))
      );
    }

    if (
      params.baseGradientHead &&
      params.baseGradientHead.length &&
      !params.token
    ) {
      params.baseGradientHead.forEach(
        (baseGradientHead) =>
          (url = url.concat(
            `&baseGradientHead=${baseGradientHead.toLowerCase()}`
          ))
      );
    }

    return fetchCall
      .http(url, {}, {}, "GET", null)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  }

  function getLeaderboard(params = { page: 0 }) {
    const queryParams = Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");

    const page = params.page ? params.page : 1;

    let url = `public/leaderboard?${queryParams}`;

    return fetchCall
      .http(url, {}, {}, "GET", null)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  }

  function queueGif(token) {
    const headers = {
      "Content-Type": "application/json",
    };

    return fetchCall
      .http("public/queue-gif", {}, headers, "POST", { token })
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  }

  function getMerkleProof(wallet) {
    const headers = {
      "Content-Type": "application/json",
    };

    return fetchCall
      .http(`public/merkle-proof?wallet=${wallet}`, {}, headers, "GET", null)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  }

  return {
    saveTokens,
    mintedTokens,
    checkWhitelist,
    generateGif,
    getRanking,
    queueGif,
    getLeaderboard,
    getMerkleProof,
  };
}

export default GradisService();
