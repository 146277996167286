import { useEffect, useState, useRef } from "react";
import styles from "./Rarity.module.css";
import GradisService from "../../services/GradisService";
import Modal from "../../commons/components/Modal/";

const gradisAncestry = [
  "Apis",
  "Beamis",
  "Bombis",
  "Botito",
  "Brokito",
  "Buddis",
  "Bullito",
  "Caubis",
  "Comfis",
  "Cralito",
  "Darkis",
  "Drudis",
  "Dubbito",
  "Duckito",
  "Dugis",
  "Elementis",
  "Entis",
  "Flamito",
  "Frogito",
  "Gmis",
  "Gradito",
  "Kangois",
  "Limis",
  "Nobis",
  "Oris",
  "Plantis",
  "Rabbis",
  "Saitais",
  "Samito",
  "Sanctis",
  "Saodis",
  "Septis",
  "Shienito",
  "Skellis",
  "Smois",
  "Spidis",
  "Spuokis",
  "Staccis",
  "Tigrito",
  "Xis",
];

const gradisBackground = [
  "Tizona",
  "Azuro",
  "Purple",
  "Garnet",
  "Graya",
  "Olive",
  "Oleo",
  "Fluoria",
  "Fluorium",
];

const gradisBaseGradientHead = ["Double", "Triple", "Quad", "Mono", "Octo"];

const Rarity = (props) => {
  const [gradis, setGradis] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginate, setPaginate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentOrder, setCurrentOrder] = useState("asc");
  const [currentToken, setCurrentToken] = useState("");
  const [ancestryValue, setAncestryValue] = useState([]);
  const [backgroundValue, setBackgroundValue] = useState([]);
  const [baseGradientHeadValue, setBaseGradientHeadValue] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedGradis, setSelectedGradis] = useState(null);

  const ancestryFilter = useRef(null);
  const ancestryFilterMobile = useRef(null);
  const backgroundFilter = useRef(null);
  const backgroundFilterMobile = useRef(null);
  const baseGradientHeadFilter = useRef(null);
  const baseGradientHeadFilterMobile = useRef(null);

  useEffect(() => {
    getGradis();
  }, []);

  useEffect(() => {
    let params = {};
    if (ancestryValue.length > 0) {
      params.ancestry = ancestryValue;
    }

    if (backgroundValue.length > 0) {
      params.background = backgroundValue;
    }

    if (baseGradientHeadValue.length > 0) {
      console.log("aqui");
      params.baseGradientHead = baseGradientHeadValue;
    }
    getGradis(params);
  }, [ancestryValue, backgroundValue, baseGradientHeadValue]);

  const getGradis = (params = {}) => {
    GradisService.getRanking(params).then((response) => {
      if (response.tokens.length) {
        setGradis(response.tokens);
        setCurrentPage(response.page + 1);
      }
      if (response.page === 0 && response.pages === 1) {
        setPaginate(false);
      } else {
        setPaginate(true);
      }
      setLoading(false);
    });
  };

  const toggleModal = (event) => {
    console.log(event.target.dataset.token);
    setSelectedGradis(
      gradis.find((g) => g.token === event.target.dataset.token)
    );
    setShowModal(!showModal);
  };

  const renderGradis = () => {
    return gradis.map((g, index) => (
      <div
        className="imageContainer"
        key={index}
        onClick={toggleModal}
        data-token={g.token}
      >
        <div className={styles.rankingLabel}>#{g.ranking}</div>
        <img
          className={styles.gradisImg}
          src={`https://storage.googleapis.com/gradis-images/${g.token}.png`}
          alt={g.token}
          data-token={g.token}
        ></img>
      </div>
    ));
  };

  const handleChangePage = (event) => {
    event.preventDefault();
    const buttonType = event.target.dataset.direction;
    if (loading) {
      return;
    }
    if (currentPage === 1 && buttonType === "back") {
      return;
    }

    if (!paginate) {
      return;
    }
    setLoading(true);
    const goTo = buttonType === "back" ? currentPage - 1 : currentPage + 1;
    const params = {
      page: goTo,
    };
    if (currentOrder === "desc") {
      params.order = "desc";
    }

    if (ancestryValue.length > 0) {
      params.ancestry = ancestryValue;
    }

    if (backgroundValue.length > 0) {
      params.background = backgroundValue;
    }

    if (baseGradientHeadValue.length > 0) {
      params.baseGradientHead = baseGradientHeadValue;
    }

    if (currentToken !== "") {
      params.token = currentToken;
    }
    getGradis(params);
  };

  const handleChangeOrder = (event) => {
    event.preventDefault();
    const buttonType = event.target.dataset.order;
    if (loading) {
      return;
    }
    if (currentOrder === buttonType) {
      return;
    }
    setLoading(true);
    setCurrentOrder(buttonType);
    const params = {
      order: buttonType,
    };

    if (ancestryValue.length > 0) {
      params.ancestry = ancestryValue;
    }

    if (backgroundValue.length > 0) {
      params.background = backgroundValue;
    }

    if (baseGradientHeadValue.length > 0) {
      params.baseGradientHead = baseGradientHeadValue;
    }

    getGradis(params);
  };

  const handleTokenNumber = (event) => {
    event.preventDefault();
    const token = event.target.value;
    setCurrentToken(token);
    setLoading(true);
    const params = {
      token,
    };
    if (currentOrder === "desc") {
      params.order = "desc";
    }
    getGradis(params);
  };

  const handleOpenCollapsible = (event) => {
    event.persist();
    event.preventDefault();
    const filterType = event.target.dataset.filter;

    switch (filterType) {
      case "ancestry":
        ancestryFilter.current.style.display === "block"
          ? (ancestryFilter.current.style.display = "none")
          : (ancestryFilter.current.style.display = "block");

        ancestryFilterMobile.current.style.display === "block"
          ? (ancestryFilterMobile.current.style.display = "none")
          : (ancestryFilterMobile.current.style.display = "block");
        break;
      case "background":
        backgroundFilter.current.style.display === "block"
          ? (backgroundFilter.current.style.display = "none")
          : (backgroundFilter.current.style.display = "block");

        backgroundFilterMobile.current.style.display === "block"
          ? (backgroundFilterMobile.current.style.display = "none")
          : (backgroundFilterMobile.current.style.display = "block");
        break;
      case "baseGradientHead":
        baseGradientHeadFilter.current.style.display === "block"
          ? (baseGradientHeadFilter.current.style.display = "none")
          : (baseGradientHeadFilter.current.style.display = "block");

        baseGradientHeadFilterMobile.current.style.display === "block"
          ? (baseGradientHeadFilterMobile.current.style.display = "none")
          : (baseGradientHeadFilterMobile.current.style.display = "block");
        break;
      default:
        break;
    }
  };

  const renderMobileAncestry = () => {
    return gradisAncestry.map((ancestry) => (
      <div className={styles.checkboxContainer}>
        <label htmlFor={ancestry}>{ancestry}</label>
        <input
          type="checkbox"
          id={ancestry}
          name="ancestry"
          value={ancestry}
          onChange={handleInputClick}
          data-filter="ancestry"
          checked={ancestryValue.includes(ancestry)}
        />
      </div>
    ));
  };

  const renderMobileBackground = () => {
    return gradisBackground.map((baseGradientHead) => (
      <div className={styles.checkboxContainer}>
        <label htmlFor={baseGradientHead}>{baseGradientHead}</label>
        <input
          type="checkbox"
          id={baseGradientHead}
          name="baseGradientHead"
          value={baseGradientHead}
          onChange={handleInputClick}
          data-filter="baseGradientHead"
          checked={baseGradientHeadValue.includes(baseGradientHead)}
        />
      </div>
    ));
  };

  const handleInputClick = (event) => {
    const filterValue = event.target.value;
    const filterType = event.target.dataset.filter;

    switch (filterType) {
      case "ancestry":
        if (ancestryValue.includes(filterValue)) {
          const newAncestryArray = ancestryValue.filter(
            (ancestry) => ancestry !== filterValue
          );
          setAncestryValue(newAncestryArray);
        } else {
          setAncestryValue((prevState) => [...prevState, filterValue]);
        }
        break;
      case "background":
        if (backgroundValue.includes(filterValue)) {
          const newBackgroundArray = backgroundValue.filter(
            (background) => background !== filterValue
          );
          setBackgroundValue(newBackgroundArray);
        } else {
          setBackgroundValue((prevState) => [...prevState, filterValue]);
        }
        break;
      case "baseGradientHead":
        if (baseGradientHeadValue.includes(filterValue)) {
          const newbaseGradientHeadArray = baseGradientHeadValue.filter(
            (baseGradientHea) => baseGradientHea !== filterValue
          );
          setBaseGradientHeadValue(newbaseGradientHeadArray);
        } else {
          setBaseGradientHeadValue((prevState) => [...prevState, filterValue]);
        }
        break;
      default:
        break;
    }
  };

  const renderMobileBaseGradientHead = () => {
    return gradisBaseGradientHead.map((baseGradientHead) => (
      <div className={styles.checkboxContainer}>
        <label htmlFor={baseGradientHead}>{baseGradientHead}</label>
        <input
          type="checkbox"
          id={baseGradientHead}
          name="baseGradientHead"
          value={baseGradientHead}
          onChange={handleInputClick}
          data-filter="baseGradientHead"
          checked={baseGradientHeadValue.includes(baseGradientHead)}
        />
      </div>
    ));
  };

  return (
    <div className={styles.rarityContainer}>
      <h1 className={styles.title}>rarity</h1>
      <div className={styles.rarityWrapper}>
        <div>
          <div className={styles.filterTitle}>Order</div>
          <button
            data-order="asc"
            onClick={handleChangeOrder}
            className={styles.filterButton}
          >
            high - low
          </button>
          <br />
          <button
            data-order="desc"
            onClick={handleChangeOrder}
            className={styles.filterButton}
          >
            low - high
          </button>
          <div className={styles.filterTitle}>Filter by</div>
          <input
            type="text"
            className={styles.filterInput}
            placeholder="token number"
            onChange={handleTokenNumber}
            defaultValue={currentToken}
          />
          <button
            type="button"
            className={styles.collapsible}
            onClick={handleOpenCollapsible}
            data-filter="ancestry"
          >
            Ancestry
          </button>
          <div className={styles.content} ref={ancestryFilter}>
            <form>{renderMobileAncestry()}</form>
          </div>
          <button
            type="button"
            className={styles.collapsible}
            onClick={handleOpenCollapsible}
            data-filter="background"
          >
            Background
          </button>
          <div className={styles.content} ref={backgroundFilter}>
            <form>{renderMobileBackground()}</form>
          </div>
          <button
            type="button"
            className={styles.collapsible}
            onClick={handleOpenCollapsible}
            data-filter="baseGradientHead"
          >
            Base Gradient Head
          </button>
          <div className={styles.content} ref={baseGradientHeadFilter}>
            <form>{renderMobileBaseGradientHead()}</form>
          </div>
        </div>
        <div className={styles.currentPage}>{renderGradis()}</div>
      </div>
      <div className={styles.rarityWrapper}>
        <div></div>
        <div className={styles.paginator}>
          <button
            className={styles.rarityButtons}
            onClick={handleChangePage}
            data-direction="back"
          >
            {"<"}
          </button>
          <div className={styles.pageLabel}>page {currentPage}</div>
          <button
            className={styles.rarityButtons}
            onClick={handleChangePage}
            data-direction="foward"
          >
            {">"}
          </button>
        </div>
      </div>
      {showModal && (
        <Modal toggleModal={toggleModal}>
          <div className={styles.rarityModalWrapper}>
            <div className={styles.iframeContainer}>
              <div className={styles.rankingLabelIframe}>
                #{selectedGradis.ranking}
              </div>
              {selectedGradis && Object.keys(selectedGradis).length > 0 && (
                <iframe
                  src={`https://2d.gradis.art/?tokenid=${selectedGradis.token}`}
                  title="Gradis"
                  frameBorder="0"
                  class="responsiveIframe"
                ></iframe>
              )}
            </div>
            <div>
              {selectedGradis && Object.keys(selectedGradis).length > 0 && (
                <>
                  <table className={styles.rarityTable}>
                    <tr>
                      <th>Trait</th>
                      <th>Value</th>
                      <th>Points</th>
                    </tr>
                    <tr>
                      <td>Ancestry</td>
                      <td>{selectedGradis.ancestry.label}</td>
                      <td>{selectedGradis.ancestry.points}</td>
                    </tr>
                    <tr>
                      <td>Body Breed</td>
                      <td>{selectedGradis.body.label}</td>
                      <td>{selectedGradis.body.points}</td>
                    </tr>
                    <tr>
                      <td>Base Color</td>
                      <td>{selectedGradis.baseColor.label}</td>
                      <td>{selectedGradis.baseColor.points}</td>
                    </tr>
                    <tr>
                      <td>Base Gradient Head</td>
                      <td>{selectedGradis.baseGradientHead.label}</td>
                      <td>{selectedGradis.baseGradientHead.points}</td>
                    </tr>
                    <tr>
                      <td>Color H1</td>
                      <td>{selectedGradis.headColor01.label}</td>
                      <td>{selectedGradis.headColor01.points}</td>
                    </tr>
                    {selectedGradis.headColor02.points > 0 && (
                      <tr>
                        <td>Color H2</td>
                        <td>{selectedGradis.headColor02.label}</td>
                        <td>{selectedGradis.headColor02.points}</td>
                      </tr>
                    )}
                    {selectedGradis.headColor03.points > 0 && (
                      <tr>
                        <td>Color H3</td>
                        <td>{selectedGradis.headColor03.label}</td>
                        <td>{selectedGradis.headColor03.points}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Base Gradient Body</td>
                      <td>{selectedGradis.baseGradientBody.label}</td>
                      <td>{selectedGradis.baseGradientBody.points}</td>
                    </tr>
                    <tr>
                      <td>Color B1</td>
                      <td>{selectedGradis.bodyColor01.label}</td>
                      <td>{selectedGradis.bodyColor01.points}</td>
                    </tr>
                    {selectedGradis.bodyColor02.points > 0 && (
                      <tr>
                        <td>Color B2</td>
                        <td>{selectedGradis.bodyColor02.label}</td>
                        <td>{selectedGradis.bodyColor02.points}</td>
                      </tr>
                    )}
                    {selectedGradis.bodyColor03.points > 0 && (
                      <tr>
                        <td>Color B3</td>
                        <td>{selectedGradis.bodyColor03.label}</td>
                        <td>{selectedGradis.bodyColor03.points}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Background</td>
                      <td>{selectedGradis.background.label}</td>
                      <td>{selectedGradis.background.points}</td>
                    </tr>
                    {selectedGradis.Extra01 && (
                      <tr>
                        <td>Extra</td>
                        <td>{selectedGradis.Extra01.label}</td>
                        <td>{selectedGradis.Extra01.points}</td>
                      </tr>
                    )}
                    {selectedGradis.Extra02 && (
                      <tr>
                        <td>Extra</td>
                        <td>{selectedGradis.Extra02.label}</td>
                        <td>{selectedGradis.Extra02.points}</td>
                      </tr>
                    )}
                    {selectedGradis.Extra03 && (
                      <tr>
                        <td>Extra</td>
                        <td>{selectedGradis.Extra03.label}</td>
                        <td>{selectedGradis.Extra03.points}</td>
                      </tr>
                    )}
                    {selectedGradis.Extra04 && (
                      <tr>
                        <td>Extra</td>
                        <td>{selectedGradis.Extra04.label}</td>
                        <td>{selectedGradis.Extra04.points}</td>
                      </tr>
                    )}
                    {selectedGradis.Extra05 && (
                      <tr>
                        <td>Extra</td>
                        <td>{selectedGradis.Extra05.label}</td>
                        <td>{selectedGradis.Extra05.points}</td>
                      </tr>
                    )}
                    {selectedGradis.Extra06 && (
                      <tr>
                        <td>Extra</td>
                        <td>{selectedGradis.Extra06.label}</td>
                        <td>{selectedGradis.Extra06.points}</td>
                      </tr>
                    )}
                    {selectedGradis.Extra07 && (
                      <tr>
                        <td>Extra</td>
                        <td>{selectedGradis.Extra07.label}</td>
                        <td>{selectedGradis.Extra07.points}</td>
                      </tr>
                    )}
                    {selectedGradis.Extra08 && (
                      <tr>
                        <td>Extra</td>
                        <td>{selectedGradis.Extra08.label}</td>
                        <td>{selectedGradis.Extra08.points}</td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td>Total Points</td>
                      <td>{selectedGradis.totalPoints}</td>
                    </tr>
                  </table>
                  <ul>
                    <li>
                      <a
                        href={`https://opensea.io/assets/0x2322b56ae00a53092e2688ab038881a0c0cf00a3/${selectedGradis.token}`}
                        target="_blank"
                        alt={selectedGradis.ancestry.label}
                        rel="noreferrer"
                        className={styles.modalLink}
                      >
                        OPENSEA LINK
                      </a>
                    </li>
                    <li>
                      <div className={styles.modalLink}>
                        Token: {selectedGradis.token}
                      </div>
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Rarity;
