import { NavLink } from "react-router-dom";
import { appRoutes } from "../../../../constants/";
import styles from "./Menu.module.css";

export const Menu = () => {
  return (
    <div className={styles.wrapper}>
      <a
        href={appRoutes.GAME}
        target="_blank"
        rel="noreferrer"
        className={styles.gameBtn}
      >
        Play
      </a>
      <a href={appRoutes.LEADERBOARD} className={styles.unactiveStyle}>
        Leaderboard
      </a>
      <a
        href={appRoutes.OPENSEA}
        target="_blank"
        rel="noreferrer"
        className={styles.unactiveStyle}
      >
        OpenSea
      </a>
      <a
        href={appRoutes.TWITTER}
        target="_blank"
        rel="noreferrer"
        className={styles.unactiveStyle}
      >
        Twitter
      </a>
      <a
        href={appRoutes.Discord}
        target="_blank"
        rel="noreferrer"
        className={styles.unactiveStyle}
      >
        Discord
      </a>
      <NavLink
        to={appRoutes.RARITY}
        className={({ isActive }) =>
          isActive ? styles.activeStyle : styles.unactiveStyle
        }
      >
        Rarity
      </NavLink>
      <a
        href={appRoutes.CONVERTITO}
        rel="noreferrer"
        target="_blank"
        className={styles.unactiveStyle}
      >
        Convertito 3D
      </a>
    </div>
  );
};
