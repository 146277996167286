import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GradisService from "../../services/GradisService";
import styles from "./Leaderboard.module.css";
import gradisLogo from "../../static/onito-season.png";
import checkmark from "../../static/checkmark.svg";
import loris from "../../static/loris.png";
import volaris from "../../static/volaris.png";
import lettersito from "../../static/lettersito.png";
import background0 from "../../static/master.png";
import background2 from "../../static/dune69.png";
import background1 from "../../static/touchgrass.png";
import background3 from "../../static/nglitchis.png";
import background4 from "../../static/leaderboard-background.jpg";

const Leaderboard = () => {
  let location = useLocation();
  const backgroundImage = [
    background0,
    background1,
    background2,
    background3,
    background4,
  ];
  const levels = ["leaderboard", "Level1", "Level2", "Level3", "DemoLevel"];
  const levelName = [
    "Onito Season",
    "Touchgrass",
    "Dune 69",
    "Nglitchis",
    "Demoito",
  ];
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [value, setValue] = useState("");
  const [currentLeaderboard, setCurrentLeaderboard] = useState("leaderboard");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page"); // Extracts the value of "page" parameter (e.g., "2")
    const stage = searchParams.get("stage");

    const params = {};

    if (page) {
      params.page = page;
      setCurrentPage(page);
    }

    if (stage) {
      setCurrentLeaderboard(stage);
      setCurrentIndex(levels.findIndex((element) => element === stage));
      params.stage = stage;
    }

    getLeaderboard(params);
  }, []);

  const getPage = (event) => {
    const buttonType = event.target.dataset.direction;
    const goTo = buttonType === "back" ? currentPage - 1 : currentPage + 1;
    if (currentPage === 1 && buttonType === "back") {
      return;
    }

    if (currentPage === totalPages && buttonType === "foward") {
      return;
    }

    const params = {
      page: goTo,
    };

    if (currentLeaderboard !== "leaderboard") {
      params.stage = currentLeaderboard;
      window.history.replaceState(
        null,
        "",
        `/leaderboard?page=${goTo}&stage=${currentLeaderboard}`
      );
    } else {
      window.history.replaceState(null, "", `/leaderboard?page=${goTo}`);
    }

    getLeaderboard(params);
  };

  const handleLeaderBoard = (event) => {
    const buttonType = event.target.dataset.direction;
    console.log(buttonType);

    if (buttonType === "prev" && currentIndex === 0) {
      return;
    }

    if (buttonType === "next" && currentIndex === 4) {
      return;
    }

    let cIndex = currentIndex;
    if (buttonType === "prev" && currentIndex > 0) {
      cIndex = currentIndex - 1;
      setCurrentIndex(cIndex);
      setCurrentLeaderboard(levels[cIndex]);
    }

    if (buttonType === "next" && currentIndex < 4) {
      cIndex = currentIndex + 1;
      setCurrentIndex(cIndex);
      setCurrentLeaderboard(levels[cIndex]);
    }

    if (cIndex !== 0) {
      const params = {
        stage: levels[cIndex],
      };
      window.history.replaceState(
        null,
        "",
        `/leaderboard?stage=${levels[cIndex]}`
      );
      getLeaderboard(params);
    } else {
      window.history.replaceState(null, "", `/leaderboard?`);
      getLeaderboard();
    }

    /* window.history.replaceState(null, "", `/leaderboard?`); */
  };

  const handleFindBy = (event) => {
    event.preventDefault();
    const params = {};
    const firstChars = value.slice(0, 2);
    switch (firstChars) {
      case "0x":
        params.wallet = value;
        break;
      case "10":
        params.token = value;
        break;
      default:
        params.twitter = value;
        break;
    }

    setCurrentPage(null);
    getLeaderboard(params);
  };

  const handleValue = (event) => {
    setValue(event.target.value);
  };

  const getLeaderboard = async (params = {}) => {
    setLoading(true);
    await GradisService.getLeaderboard(params).then((response) => {
      if (response.records.length) {
        setRecords(response.records);
        setCurrentPage(response.page + 1);
        setTotalPages(response.pages);
      }
      setLoading(false);
    });
  };

  const handleClassName = (position) => {
    switch (position) {
      case 1:
        return styles.firstPosition;
      case 2:
      case 3:
        return styles.secondThirdPosition;
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return styles.restPositions;
      default:
        break;
    }
  };

  const handleItemsito = (itemsito) => {
    switch (itemsito) {
      case "0":
        return loris;
      case "2":
        return volaris;
      case "3":
        return lettersito;
      default:
        break;
    }
  };

  const showGradisImg = (record) => {
    if (record.twitter === "") {
      return false;
    }
    return record.twitter !== "" && record.wallet && record.token;
  };

  const showItemsito = (record) => {
    if (record.twitter === "") {
      return false;
    }

    return record.item !== "-1";
  };

  const renderRecords = () => {
    if (records.length === 0) {
      return;
    }

    return records.map((record, index) => (
      <tr className={handleClassName(record.position)} key={index}>
        <td className={styles.tdContainer}>{record.position}</td>
        <td className={styles.tdContainer}>
          <div className={styles.twitterContainer}>
            {record.twitter === "" ? "Unverified Player" : record.twitter}
            {record.twitter !== "" ? (
              <img
                src={checkmark}
                width="13"
                height="13"
                className={styles.checkmark}
                alt=""
              />
            ) : (
              <span />
            )}
          </div>
        </td>
        <td className={styles.tdContainer}>
          <div className={styles.timeContainer}>
            {record.endTime}
            <div style={{ display: "flex", alignItems: "center" }}>
              {showItemsito(record) && currentLeaderboard !== "leaderboard" && (
                <img
                  src={handleItemsito(record.item)}
                  width="10"
                  height="10"
                  className={styles.itemsito}
                  alt=""
                />
              )}
              {showGradisImg(record) && (
                <div className={styles.holditoContainer}>
                  <img
                    className={styles.gradisHead}
                    alt=""
                    src={`https://cdn.gradis.art/${
                      record.token.startsWith(1) ? "gradis" : "grados"
                    }-pfp/${record.token}.${
                      record.token.startsWith(1) ? "png" : "jpg"
                    }`}
                  />
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div
      className={styles.leaderboardWrapper}
      style={{
        backgroundImage: `url(${backgroundImage[currentIndex]})`,
        backgroundPosition: "center bottom",
        backgroundSize: "cover",
      }}
    >
      <div className={styles.leaderboardContainer}>
        <div className={styles.tableContainer}>
          <div className={styles.gradisLogoContainer}>
            <img src={gradisLogo} alt="logo" className={styles.gradisLogo} />
          </div>
          <div className={styles.header}>
            <div className={styles.headerElements}>
              <div className={styles.title}>{levelName[currentIndex]}</div>
              <div>
                <button
                  className={styles.prevLBBtn}
                  data-direction="prev"
                  onClick={handleLeaderBoard}
                >
                  {"<"}
                </button>
                <button
                  className={styles.nextLBBtn}
                  data-direction="next"
                  onClick={handleLeaderBoard}
                >
                  {">"}
                </button>
              </div>
            </div>
            <form onSubmit={handleFindBy}>
              <input
                className={styles.searchInput}
                placeholder="Search... "
                onChange={handleValue}
              />
            </form>
          </div>
          <div className={styles.tableDataWrapper}>
            <div className={styles.tableData}>
              <table className={styles.table}>
                <tbody>{renderRecords()}</tbody>
              </table>
            </div>
            <br />
            <div className={styles.buttonsContainer}>
              <div>
                <button
                  className={styles.leaderboardBtn}
                  data-direction="back"
                  onClick={getPage}
                >
                  {"<"}
                </button>
                <button
                  className={styles.leaderboardBtn}
                  data-direction="foward"
                  onClick={getPage}
                >
                  {">"}
                </button>
              </div>
              <div>
                <a
                  href="https://onito.gradis.art"
                  className={styles.playBtn}
                  target="_blank"
                  rel="noreferrer"
                >
                  PLAY
                </a>
              </div>
              <div className={styles.lastElement}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
