import { useLocation } from "react-router-dom";
import styles from "./Footer.module.css";
import { Menu } from "./Menu";

const Footer = () => {
  let location = useLocation();
  if (
    location.pathname.startsWith("/lores") ||
    location.pathname.startsWith("/mint") ||
    location.pathname.startsWith("/leaderboard") ||
    location.pathname.startsWith("/rarity")
  ) {
    return;
  }
  return (
    <div className={styles.footer} style={{ display: "none" }}>
      <Menu />
    </div>
  );
};

export default Footer;
