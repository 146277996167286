import { Link } from 'react-router-dom';
import { appRoutes } from '../../../../constants/';
import styles from './Logo.module.css';

export const Logo = () => {
  return (
    <Link to={appRoutes.HOME} className={styles.Logo}>
      gradis
    </Link>
  );
};
