import styles from "./Modal.module.css";

const Modal = ({ children, toggleModal }) => {
  return (
    <div className={styles.wrapper}>
      <div>
        <button className={styles.closeBtn} onClick={toggleModal}>
          &times;
        </button>
      </div>
      {children}
    </div>
  );
};

export default Modal;
