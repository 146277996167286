export const appRoutes = {
  HOME: "/",
  MINT: "/",
  MINT_INFO: "/mint-info",
  LORES: "lores",
  LORE: "lores/:gradisId",
  GIF: "gif",
  RARITY: "rarity",
  Discord: "https://discord.gg/tJV9NdVDfp",
  TWITTER: "https://twitter.com/_GRADIS_/",
  OPENSEA: "https://opensea.io/collection/gradis",
  CONVERTITO: "https://convertito.gradis.art/",
  LEADERBOARD: "/leaderboard",
  GRADOS: "/grados-admin",
  GAME: "https://onito.gradis.art",
};
